
import React, {useState} from "react";
import Karta from "./components/UI/karta/Karta";

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import 'bootstrap/dist/css/bootstrap.min.css';

import './test.css';

function App() {
    const dataFile = document.location.hostname === 'localhost' ? '/data.json' : 'https://deep.thebest4wedding.com/seagrass-admin/json.php';
    const domena = 'http://deep.thebest4wedding.com/';
  
    const [lokacije, setLokacije] = useState([]);

    async function ucitajPodatke(){
        const response = await fetch(dataFile);
        const xxx = await response.json();
        setLokacije(xxx);
        console.log('asink');
    }
    
    if(lokacije.length === 0)
      ucitajPodatke();
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Karta lokacije={lokacije} domena={domena} />
    </ThemeProvider>
    
  );
}

export default App;