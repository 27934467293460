import React, {useState} from "react";
import css from './BoxItem.module.css';

import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Video from '../video/Video';
import Galerija from '../galerija/Galerija';
import Vr from '../vr/Vr';
import Tekst from '../tekst/Tekst';

const TekstOpsirno = props => {
    //console.log('SSSSSS: '+props.domena+'/seagrass-admin/'+props.bgfajl);
    return (
        <>
       
        <Modal show={props.show} fullscreen={props.fullscreen} onHide={props.onHide} style={{ 
                backgroundImage: `url(${props.domena+'/seagrass-admin/'+props.bgfajl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }}>
            <Modal.Header style={{borderBottom:'none'}} closeButton>
            </Modal.Header>
            <Modal.Body style={{paddingLeft:'7%'}} onClick={props.onHide}><Tekst ajtem={props.ajtem} domena={props.domena} /></Modal.Body>
        </Modal>
        </>
    );
}


const BoxItem = props => {
    const slika = props.domena+'thumb.php?src=seagrass-admin/'+props.ajtem.thumb+'&w=800&h=600';
    

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }

    console.log(props.ajtem);
    return(
        <Col>
            <div className={css.prozirni_box} onClick={handleShow}>
                <div className={css.kontent_box}>
                    <img src={slika} className={css.thumb} alt='' />
                    <div className={css.naslov}>{props.ajtem.naslov}</div>
                </div>
                
            </div>
            {
                props.ajtem.tip === '1'
                ? <TekstOpsirno show={show} onHide={() => setShow(false)} ajtem={props.ajtem} domena={props.domena} fullscreen={fullscreen} bgfajl={props.ajtem.fajl} />
                : <Opsirno show={show} onHide={() => setShow(false)} ajtem={props.ajtem} domena={props.domena} />             
            }
            
        </Col>
    )
}


const Opsirno = props => {
    
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="sirina-90 ne_prozirno"
            aria-labelledby="contained-modal-title-vcenter "
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="bijelo">
                    {props.ajtem.naslov}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.ajtem.tip === '4'
                    ? <Vr ajtem={props.ajtem} domena={props.domena} />
                    : ''               
                }
                {
                    props.ajtem.tip === '3'
                    ? <Video ajtem={props.ajtem} domena={props.domena} />
                    : ''               
                }
                {
                    props.ajtem.tip === '2'
                    ? <Galerija ajtem={props.ajtem} domena={props.domena} />
                    : ''               
                }
            </Modal.Body>
        </Modal>
    );
}

export default BoxItem;