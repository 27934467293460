import React, {useRef, useEffect, useState} from "react";


const Vr = props => {
    const [height, setHeight] = useState(200);
    const ref = useRef();
    const fajl = props.domena+'vr.php?slika=seagrass-admin/'+props.ajtem.fajl;

    useEffect(() => {
      setHeight((ref.current.clientWidth / 2.83));
    }, [])

    return (<iframe ref={ref} allowfullscreen="" src={fajl} width="100%" height={height+'px'} title="vr"></iframe>);
}


export default Vr;