import React from 'react';
import BoxItem from '../boxItem/BoxItem';


import Container from 'react-bootstrap/Container';
//import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

//import css from './TockaOpsirno.module.css';

const TockaOpsirno = props => {
  //const ajtemiArr = props.ajtemi;
    //ovaj onklik će trebat stavi na neki gumb
    return (
        <Container fluid>
          <Row md={3} id={props.id}>
          {props.ajtemi.map(element =>    
            <BoxItem key={element.id} thumb={element.thumb} naslov={element.naslov} ajtem={element} domena={props.domena} />                   
          )}            
          </Row>       
        </Container>
      )
}

export default TockaOpsirno;